@font-face {
  font-family: 'JosefinSans';
  font-weight: 300;
  src: url('./assets/fonts/JosefinSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'JosefinSans';
  font-weight: 700;
  src: url('./assets/fonts/JosefinSans-SemiBold.ttf') format('truetype');
}

.appContainer {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.promoOverlay {
  position: fixed;
  top: 0;
  left: 0;
  widtH: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 4000;
  background-color: rgba(0,0,0,0.3);
}

.promoWrapper {
  width: auto;
  height: auto;
  padding: 0.8vw;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.promoImg {
  width: auto;
  height: 90vh;
  object-fit: cover;
}

.whatsappLogo {
  width: 8vw;
  height: 8vw;
  object-fit: contain;
  position: fixed;
  right: 1vw;
  bottom: 5vw;
  z-index: 200;
  transform: scale(1);
  transition: transform 0.3s ease-out;
}

.whatsappLogo:hover {
  transform: scale(1.1);
}

.measureOverlay {
  width: 98vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px rgba(0, 0, 0, 0.07)
  ;
  z-index: 400;
}

.measureOverlayCloseIcon {
  width: 35px;
  height: 35px;
  color: #FD7F25;
  position: absolute;
  top: 1vw;
  right: 2vw;
  cursor: pointer;
  transition: filter 0.3s ease-out;
}

.measureOverlayCloseIcon:hover {
  filter: brightness(0.8);
}

.measureOverlayColumn {
  width: 45%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.measureOverlayTitle {
  font-family: 'JosefinSans';
  font-size: 1.7vw;
  font-weight: 700;
  color: #5B6770;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
}

.measureOverlayImg {
  width: 55%;
  height: auto;
  object-fit: cover;
}

.navbar {
  width: 90%;
  height: 8%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #5B6770;
  overflow: hidden;
}

.navBtns {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.navBtn {
  font-family: 'JosefinSans';
  font-size: 1.4vw;
  font-weight: 700;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 1.5vw;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
}

.navBtn:hover {
  opacity: 0.6;
}

.socialBtns {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.socialIcon {
  width: auto;
  height: 1.8vw;
  object-fit: cover;
  margin-left: 1vw;
  cursor: pointer;
  opacity: 0.8;
  transition: all 0.3s ease-out;
}

.socialIcon:hover {
  filter: brightness(0.8);
}

.navPhoneNumbers {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; 
}

.navPhoneNumberIcon {
  color: #5B6770;
  width: 17px;
  height: 17px;
  margin-right: 1vw;
  margin-bottom: 0.1vw;
}

.navPhoneNumberLink {
  font-family: 'JosefinSans';
  font-size: 1vw;
  font-weight: 300;
  color: #5B6770;
  margin-top: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.navPhoneNumberLink:focus {
  outline: none;
}

.sectionsContainer {
  width: 100%;
  flex: 1;
  background-color: #fff;
  position: relative;
}

.section {
  width: 100%;
  flex: 1;
  background-color: #e3e6e9;
  overflow: hidden;
  position: absolute;
  left: 0;
}

.headerImgContainer {
  position: absolute;
  top: 0;
  right: -20vw;
  height: 100%;
  width: 90vw;
  transform: skew(15deg);
  -webkit-transform: skew(15deg);
  -moz-transform: skew(15deg);
  overflow: hidden;
}

.headerImg {
  width: 150%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: skew(-15deg) translate(-50%, -50%);
  -webkit-transform: skew(-15deg) translate(-50%, -50%);
  -moz-transform: skew(-15deg) translate(-50%, -50%);
}

.headerInfoContainer {
  flex: 1;
  width: 30vw;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.logo {
  width: 15vw;
  height: auto;
  object-fit: cover;
  margin-bottom: 4vh;
}

.headerInfoCTA {
  width: 60%;
  font-family: 'JosefinSans';
  font-size: 1.5vw;
  line-height: 2.2vw;
  font-weight: 300;
  color: #5B6770;
  margin-top: 0;
  margin-bottom: 12vh;
  cursor: pointer;
  transition: filter 0.3s ease-out;
}

.ctaGlyph {
  height: auto;
  margin-left: 0.8vw;
  transition: margin-left 0.5s ease-out;
  position: relative;
  opacity: 0.8;
}

.headerInfoCTA:hover {
  filter: brightness(0.5);
}

.headerInfoCTA:hover .ctaGlyph {
  margin-left: 1.6vw;
}

.glyph {
  position: absolute;
  top: 1px;
  left: 0;
}

.usImgContainer {
  width: 30%;
  height: 100%;
  transform: skew(15deg);
  -webkit-transform: skew(15deg);
  -moz-transform: skew(15deg);
  overflow: hidden;
  position: absolute;
  left: 0%;
  top: 0%;
}

.usImg {
  width: 220%;
  height: 110%;
  position: absolute;
  top: 50%;
  left: 40%;
  transform: skew(-15deg) translate(-50%, -50%);
  -webkit-transform: skew(-15deg) translate(-50%, -50%);
  -moz-transform: skew(-15deg) translate(-50%, -50%);
  object-fit: cover;
}

.usInfoContainer {
  width: 40%;
  height: 100%;
  position: absolute;
  right: 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.usInfoText {
  width: 100%;
  font-family: 'JosefinSans';
  font-size: 1.3vw;
  line-height: 2.3vw;
  font-weight: 300;
  color: #5B6770;
  margin-top: 0;
  margin-bottom: 0;
}

.usInfoCTA {
  align-self: flex-start;
  font-family: 'JosefinSans';
  font-size: 1.5vw;
  line-height: 3vw;
  font-weight: 300;
  color: #FD7F25;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
}

.usInfoCTA:hover .ctaGlyph {
  margin-left: 1.6vw;
}

.contactSection {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.contactInfoContainer {
  width: 20%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 5vw;
}

.contactFormContainer {
  width: 40%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.contactFormButton {
  height: 5.5vh;  
  width: 15vw;
  border: none;
  position: relative;
  align-self: flex-end;
  margin-top: 2vh;
  cursor: pointer;
  transition: filter 0.3s ease-out;
}

.contactFormButton:hover {
  filter: brightness(0.9);
}

.contactFormButton:disabled {
  opacity: 0.4;
}

.contactFormButton:focus {
  outline: none;
}

.contactButtonText {
  font-family: 'JosefinSans';
  font-size: 1.2vw;
  line-height: 3vw;
  font-weight: 300;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  position: absolute;
  left: 1vw;
  top: 50%;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
}

.contactFormMeasureButton {
  width: 100%;
  text-align: left;
  height: auto;
  font-family: 'JosefinSans';
  font-size: 1.2vw;
  line-height: 3vw;
  font-weight: 700;
  color: #5B6770;
  margin-top: 2vh;
  margin-bottom: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transition: filter 0.3s ease-out;
}

.contactFormMeasureButton:hover {
  filter: brightness(0.5);
}

.contactFormMeasureButtonIcon {
  width: 20px;
  height: 20px;
  margin-right: 1vw;
  color: #5B6770;
}

.contactMap {
  width: 80%;
  height: auto;
  object-fit: cover;
  margin-bottom: 5vh;
}

.contactInfoText {
  font-family: 'JosefinSans';
  font-size: 1.2vw;
  line-height: 2vw;
  font-weight: 300;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
}

.productsSection {
  width: 100%;
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
}

.productsInnerLegend {
  font-family: 'JosefinSans';
  font-size: 1.2vw;
  font-weight: 300;
  color: #5B6770;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  text-align: center;
}

.productsInner {
  width: 90vw;
  height: auto;
  margin-left: 5vw;
  margin-right: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.productsInnerTitle {
  font-family: 'JosefinSans';
  font-size: 3vw;
  font-weight: 700;
  color: #5B6770;
  margin-top: 10vh;
  margin-bottom: 3vh;
  margin-right: 3vw;
  width: 10%;
  text-align: left;
}

.productContainer {
  height: auto;
  width: 100%;
  margin: 4vh 0 4vh 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.productImg {
  height: 30vw;
  width: 30vw;
  object-fit: cover;
}

.productInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.productsDisplayInfoCloseContainer {
  position: absolute;
  top: 20px;
  right: 20px;
  height: auto;
  width: auto;
  cursor: pointer;
}

.productsDisplayTitle {
  font-family: 'JosefinSans';
  font-size: 1.8vw;
  font-weight: 700;
  color: #5B6770;
  margin-top: 10vh;
  margin-bottom: 2vh;
  width: auto;
  text-align: right;
}

.productsDisplayText {
  font-family: 'JosefinSans';
  font-size: 1.2vw;
  line-height: 1.8vw;
  font-weight: 300;
  color: #4d687c;
  margin-top: 0;
  margin-bottom: 8vh;
  width: 80%;
  text-align: right;
}

.productsCTA {
  font-size: 1.4vw;
  width: auto;
  text-align: right;
  text-decoration: underline;
  align-self: flex-end;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .promoImg {
    width: 70vw;
    height: auto;
    object-fit: cover;
  }

  .whatsappLogo {
    width: 15vw;
    height: 15vw;
    right: 1vw;
    bottom: 3vw;
  }
  
  .whatsappLogo:hover {
    transform: scale(1);
  }

  .measureOverlayCloseIcon {
    width: 40px;
    height: 40px;
    top: 3vw;
    right: 3vw;
  }
  
  .measureOverlayCloseIcon:hover {
    filter: brightness(1);
  }
  
  .measureOverlayColumn {
    width: 50%;
    height: 95%;
  }
  
  .measureOverlayTitle {
    font-size: 2.7vw;
    line-height: 4.5vw;
    font-weight: 700;
    text-align: center;
    width: 80%;
  }
  
  .measureOverlayImg {
    width: 90%;
    height: auto;
    object-fit: cover;
  }

  .navbar {
    width: 90%;
    height: 10%;
  }

  .noNavbarMobile {
    display: none;
  }
  
  .navBtn {
    font-size: 2.5vw;
  }

  .socialIcon {
    width: auto;
    height: 4vh;
    object-fit: cover;
    margin-right: 5;
  }
  
  .socialIcon:hover {
    filter: brightness(1);
  }

  .navPhoneNumbers {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  .navPhoneNumberLink {
    font-size: 2vw;
  }
  
  .sectionsContainer {
    width: 100%;
    height: 88%;
    background-color: #e3e6e9;
    position: relative;
  }
  
  .section {
    width: 100%;
    height: 88%;
    overflow: hidden;
    position: absolute;
    left: 0;
  }
  
  .headerImgContainer {
    position: absolute;
    top: 0;
    right: 0vw;
    height: 100%;
    width: 60vw;
    transform: skew(15deg);
    -webkit-transform: skew(15deg);
    -moz-transform: skew(15deg);
    overflow: hidden;
    z-index: 10;
  }
  
  .headerImg {
    width: 250%;
    height: 110%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: skew(-15deg) translate(-50%, -50%);
    -webkit-transform: skew(-15deg) translate(-50%, -50%);
    -moz-transform: skew(-15deg) translate(-50%, -50%);
  }
  
  .headerInfoContainer {
    flex: 1;
    width: 40vw;
    margin-left: 0;
    padding-left: 6%;
  }
  
  .logo {
    width: 20vw;
    height: auto;
    object-fit: cover;
    margin-bottom: 3vh;
  }
  
  .headerInfoCTA {
    width: 70%;
    font-size: 2.6vw;
    line-height: 4vw;
    margin-bottom: 10vh;
  }
  
  .headerInfoCTA:hover {
    filter: none;
  }
  
  .headerInfoCTA:hover .ctaGlyph {
    margin-left: 0.8vw;
  }

  .glyph {
    position: relative;
    top: 1px;
    left: 0;
  }
  
  .usImgContainer {
    margin-left: -20%;
    width: 50%;
    height: 100%;
    transform: skew(15deg);
    -webkit-transform: skew(15deg);
    -moz-transform: skew(15deg);
    overflow: hidden;
  }
  
  .usImg {
    width: 300%;
    height: 105%;
    position: absolute;
    top: 50%;
    left: 40%;
    transform: skew(-15deg) translate(-50%, -50%);
    -webkit-transform: skew(-15deg) translate(-50%, -50%);
    -moz-transform: skew(-15deg) translate(-50%, -50%);
    object-fit: cover;
  }
  
  .usInfoContainer {
    width: 50%;
    height: 100%;
    justify-content: flex-start;
    right: 10%;
  }
  
  .usInfoText {
    font-size: 2.2vw;
    line-height: 3.9vw;
    margin-top: 7vh;
    margin-bottom: 7vh;
  }
  
  .usInfoCTA {
    align-self: flex-end;
    font-size: 2.6vw;
    line-height: 5.2vw;
    margin-right: 5vw;
  }
  
  .usInfoCTA:hover .ctaGlyph {
    margin-left: 0.8vw;
  }
  
  .contactInfoContainer {
    width: 30%;
    height: 45vh;
    margin-right: 3vw;
  }
  
  .contactFormContainer {
    width: 50%;
    height: 55vh;
  }
  
  .contactFormButton {
    height: 4vh;  
    width: 30vw;
    margin-top: 1vh;
  }
  
  .contactFormButton:hover {
    filter: brightness(1);
  }

  .contactFormMeasureButton {
    font-size: 2vw;
    line-height: 4vw;
    margin-top: 3vh;
  }
  
  .contactFormMeasureButton:hover {
    filter: brightness(1);
  }
  
  .contactFormMeasureButtonIcon {
    margin-right: 2vw;
  }
  
  .contactButtonText {
    font-family: 'JosefinSans';
    font-size: 2.3vw;
    line-height: 3.5vw;
    font-weight: 300;
    color: #fff;
    margin-top: 0;
    margin-bottom: 0;
    position: absolute;
    left: 1vw;
    top: 50%;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
  }
  
  .contactInfoText {
    font-size: 2.3vw;
    line-height: 4vw;
  }

  .productsInnerLegend {
    font-size: 2.4vw;
  }
  
  .productsInner {
    width: 90vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .productsInnerTitle {
    font-size: 4vw;
    margin-top: 6vh;
    margin-bottom: 2vh;
  }

  .productContainer {
    height: auto;
    width: 100%;
    margin: 2vh 0 2vh 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .productImg {
    height: 50vw;
    width: 40vw;
  }
  
  .productsDisplayTitle {
    font-size: 3vw;
    margin-top: 2vh;
    margin-bottom: 2vh;
    width: 90%;
    margin-left: 5%;
  }
  
  .productsDisplayText {
    font-size: 2vw;
    line-height: 3vw;
    text-align: right;
    margin-bottom: 2vh;
    width: 90%;
    margin-left: 10%;
  }
  
  .productsCTA {
    font-size: 2.4vw;
    margin-right: 0;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .promoImg {
    width: 95vw;
    height: auto;
    object-fit: cover;
  }

  .whatsappLogo {
    width: 25vw;
    height: 25vw;
    right: 0;
    bottom: 5vw;
  }

  .measureOverlay {
    display: block;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .measureOverlayCloseIcon {
    width: 30px;
    height: 30px;
    top: 5vw;
    right: 5vw;
  }
  
  .measureOverlayColumn {
    margin-top: 10vh;
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .measureOverlayTitle {
    font-size: 5.5vw;
    line-height: 8vw;
    width: 80%;
    margin-bottom: 3vh;
  }
  
  .measureOverlayImg {
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-bottom: 5vh;
    margin-left: 5vw;
  }

  .navBtns {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .navBtn {
    font-size: 4.2vw;
    margin-right: 2.5vw;
  }

  .navBtn:last-child {
    margin-right: 0;
  }
  
  .navPhoneNumbers {
    opacity: 0;
    pointer-events: none;
  }
  
  .headerImgContainer {
    position: absolute;
    top: 0;
    right: 0vw;
    height: 100%;
    width: 60vw;
    transform: skew(15deg);
    -webkit-transform: skew(15deg);
    -moz-transform: skew(15deg);
    overflow: hidden;
    z-index: 10;
  }
  
  .headerImg {
    width: 280%;
  }
  
  .headerInfoContainer {
    flex: 1;
    width: 45vw;
    margin-left: 0;
    padding-left: 7%;
  }
  
  .logo {
    width: 35vw;
    height: auto;
    object-fit: cover;
    margin-bottom: 3vh;
  }
  
  .headerInfoCTA {
    width: 90%;
    font-size: 4vw;
    line-height: 7vw;
    margin-bottom: 3vh;
  }

  .headerButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 7vh;
  }

  .headerIcon {
    width: auto;
    height: 5.5vh;
    object-fit: cover;
    margin-right: 5vw;
  }

  .glyph {
    position: absolute;
    top: 1px;
    left: 0;
  }

  .usSection {
    justify-content: flex-end;
  }
  
  .usImgContainer {
    width: 70%;
    height: 100%;
    position: absolute;
    left: -10%;
    top: 0;
  }

  .usImg {
    width: 300%;
    height: 105%;
    position: absolute;
    top: 50%;
    left: 60%;
    transform: skew(-15deg) translate(-50%, -50%);
    -webkit-transform: skew(-15deg) translate(-50%, -50%);
    -moz-transform: skew(-15deg) translate(-50%, -50%);
    object-fit: cover;
  }
  
  .usInfoContainer {
    width: 50%;
    height: 100%;
    justify-content: flex-start;
    right: 5%;
  }
  
  .usInfoText {
    font-size: 4vw;
    line-height: 7vw;
    margin-top: 5vh;
    text-align: right;
    margin-bottom: 5vh;
  }
  
  .usInfoCTA {
    align-self: flex-end;
    font-size: 4vw;
    line-height: 8vw;
    margin-left: 2vw;
  }
  
  .usInfoCTA:hover .ctaGlyph {
    margin-left: 2vw;
  }

  .contactSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
  }
  
  .contactInfoContainer {
    width: 85%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-right: 0;
    margin-bottom: 1.5vh;
    margin-top: 3vh;
  }

  .rightContactInfoText {
    text-align: right;
  }
  
  .contactFormContainer {
    width: 85%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .contactFormButton {
    height: 4vh;  
    width: 45vw;
    border: none;
    position: relative;
    align-self: flex-end;
    margin-top: 1vh;
    cursor: pointer;
    transition: filter 0.3s ease-out;
  }
  
  .contactFormButton:hover {
    filter: brightness(0.9);
  }
  
  .contactFormButton:disabled {
    opacity: 0.4;
  }
  
  .contactFormButton:focus {
    outline: none;
  }

  .contactFormMeasureButton {
    font-size: 4.5vw;
    line-height: 6.5vw;
    margin-top: 4vh;
  }
  
  .contactFormMeasureButtonIcon {
    margin-right: 2vw;
  }
  
  .contactButtonText {
    font-family: 'JosefinSans';
    font-size: 3.2vw;
    line-height: 3vw;
    font-weight: 300;
    color: #fff;
    margin-top: 0;
    margin-bottom: 0;
    position: absolute;
    left: 1vw;
    top: 50%;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
  }
  
  .contactMap {
    display: none;
  }
  
  .contactInfoText {
    font-family: 'JosefinSans';
    font-size: 3.5vw;
    line-height: 5vw;
    font-weight: 300;
    color: #666;
    margin-top: 0;
    margin-bottom: 0;
  }

  .productsInnerLegend {
    font-size: 3.5vw;
  }
  
  .productsInner {
    width: 100%;
    height: auto;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .productsInnerTitle {
    font-size: 8vw;
    margin-left: 5%;
  }

  .productContainer {
    height: auto;
    width: 100%;
    margin: 4vh 0 4vh 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .productImg {
    width: 100%;
    height: 70vw;
  }
  
  .productNameContainer {
    display: none;
  }
  
  .productsDisplayTitle {
    font-size: 6.5vw;
    margin-bottom: 2.5vh;
    margin-top: 7vh;
    width: 90%;
    margin-right: 5%;
  }
  
  .productsDisplayText {
    align-self: flex-end;
    font-size: 4.2vw;
    line-height: 7.5vw;
    margin-bottom: 5vh;
    width: 80%;
    margin-left: 0;
    margin-right: 5%;
  }
  
  .productsCTA {
    font-size: 5.4vw;
    margin-right: 5%;
  }

  .productsDisplayInfoCloseContainer {
    position: absolute;
    top: 0;
    right: 10px;
  }
}