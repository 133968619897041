.formInputSimpleContainer {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.formInputSimpleLabel {
  margin-bottom: 1.5vh;
  font-family: 'JosefinSans';
  font-weight: 700;
  font-size: 0.9vw;
  line-height: 1vw;
  color: rgba(91, 103, 112, 0.8);
}

.formTextArea {
  color: #666;
  background-color: #f8f8f8;
  border-bottom: none;
  border-right: none;
  border-top: none;
  border-left: none;
  font-family: 'JosefinSans';
  font-weight: 300;
  font-size: 1.2vw;
  line-height: 1.9vw;
  margin-top: 0;
  margin-bottom: 2vh;
  height: 20vh;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 1vw;
  resize: none;
}

.formTextArea::placeholder {
  color: rgba(102, 102, 102, 1);
}

.formTextArea:focus {
  outline: none;
}

@media (orientation: portrait) and (max-width: 1024px) and (min-width: 768px) {

  .formTextArea {
    height: 15vh;
  }

  .formInputSimpleLabel {
    margin-bottom: 1vh;
    font-size: 1.7vw;
    line-height: 2vw;
  }
  
  .formTextArea {
    font-size: 2.3vw;
    line-height: 3vw;
    padding-left: 2vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {

  .formTextArea {
    height: 15vh;
  }

  .formInputSimpleLabel {
    margin-bottom: 1vh;
    font-size: 3vw;
    line-height: 3vw;
  }
  
  .formTextArea {
    font-size: 4vw;
    line-height: 6vw;
  }
}